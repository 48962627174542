import useSWRInfinite from 'swr/infinite';
import { useMemo } from 'react';
import { fetchJson } from '~/lib/fetch';
import { CollectionProps, CollectionHookProps } from '~/types/collections';
import { CollectionType } from '~/components/smartcollections/components/types';

// A function to get the SWR key of each page,
// its return value will be accepted by `fetcher`.
// If `null` is returned, the request of that page won't start.
// This is only used on the homepage to lazy load the rest of the collections.
const getKey = (
  pageIndex: number,
  previousPageData?: { collections: CollectionProps[] },
  userDevice: string = '',
) => {
  // reached the end or prevent more requests after the "second page" has been fetched
  if (
    (previousPageData && !previousPageData?.collections?.length) ||
    pageIndex > 1
  )
    return null;

  // first page fetch the first 4 collections
  if (pageIndex === 0) {
    return `/api/smartcollections/personal/?include_deals=true&page=1&limit=4&user_device=${userDevice}`;
  }

  // Fetch the rest of the collections after the first 4
  return `/api/smartcollections/personal/?include_deals=true&offset=4&user_device=${userDevice}`;
};

export function useCollections(
  fallbackData?: CollectionProps,
  userDevice: string = '',
): CollectionHookProps {
  const { data, error, mutate, setSize, size, isValidating } = useSWRInfinite(
    (pageIndex, previousPageData) =>
      getKey(pageIndex, previousPageData, userDevice),
    fetchJson,
    fallbackData
      ? { fallbackData: [fallbackData], revalidateFirstPage: false }
      : { revalidateFirstPage: false },
  );

  const sortedCollections = useMemo(() => {
    return (
      data
        ?.flatMap((page) => page.collections ?? [])
        ?.filter(
          (collection) =>
            !collection.query_parameters ||
            (collection.query_parameters && collection.deals?.length > 0) ||
            collection.component_type === CollectionType.COUNTDOWN,
        ) ?? []
    );
  }, [data]);

  return {
    collections: sortedCollections ?? [],
    error,
    isLoading: isValidating,
    setSize,
    size,
    mutate,
  };
}
