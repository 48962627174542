import React from 'react';
import { FieldValues } from 'react-hook-form';
import { Button, Form, Link } from '@appsumo/dorado-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';

import { useEmailSubscribe } from '~/lib/util/hooks';
import { UseEmailSubscribeProps } from '~/lib/util/hooks/emailSubscribe';
import { emailValidationSchema } from '~/schemas/email';

type link = {
  name: string;
  ref: string;
};

interface ComponentNameProps {
  componentName?: string;
  content?: string;
  submitText?: string;
  className?: string;
  buttonClassName?: string;
  inputLabelClasses?: string;
  link?: link;
}

export const EmailCapture = ({
  componentName,
  content,
  submitText = 'Get 10% Off',
  className,
  buttonClassName = 'w-full md:w-fit',
  inputLabelClasses = 'text-white font-semibold font-header',
  link = undefined,
}: ComponentNameProps): React.ReactElement => {
  const emailSubscribeProps: UseEmailSubscribeProps = {
    customFormData: { component: componentName, content },
  };

  const {
    hasEmailError,
    isEmailSubmitted,
    isEmailValid,
    onFormChangeEmail,
    submitEmail,
  } = useEmailSubscribe(emailSubscribeProps);

  function onSubmit() {
    submitEmail();
  }

  const watch = (data: FieldValues) => {
    onFormChangeEmail(data);
  };

  const emailErrorMessage =
    hasEmailError || 'A valid email address is required to get 10% off.';
  const errorMessage = !isEmailValid || hasEmailError ? emailErrorMessage : '';

  if (isEmailSubmitted) {
    return (
      <div className="mt-4 flex items-center rounded bg-dollar-light-40 px-4 py-3 text-midnight">
        <FontAwesomeIcon
          icon={faCircleCheck}
          height="18"
          className="mr-2 text-lg"
        />
        <span className="text-sm">
          Success! Check your inbox for the coupon code and start saving now!
        </span>
      </div>
    );
  }

  return (
    <Form
      onSubmit={onSubmit}
      resolver={yupResolver(emailValidationSchema)}
      watch={watch}
    >
      <div className={className}>
        <div className="w-full max-w-2xl">
          <Form.Input
            name="email"
            error={errorMessage}
            label="Email"
            labelClasses={inputLabelClasses}
          />
        </div>
        <div>
          <Button
            type="submit"
            disabled={!!errorMessage}
            className={buttonClassName}
          >
            {submitText}
          </Button>
          {link && (
            <Link
              className="sm: relative left-1 top-2 md:left-2 md:top-0 lg:top-0"
              href={link.ref}
              styled
            >
              {link.name}
            </Link>
          )}
        </div>
      </div>
    </Form>
  );
};
