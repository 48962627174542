import { useCallback, useMemo, useState } from 'react';
import useScript from './hook';

export default function useBeacon() {
  const [isLoaded, setIsLoaded] = useState(false);

  const init = useCallback(() => {
    !(function (e, t, n) {
      if (
        ((e.Beacon = n =
          function (t, n, a) {
            e.Beacon.readyQueue.push({
              method: t,
              options: n,
              data: a,
            });
          }),
        (n.readyQueue = []),
        'complete' === t.readyState)
      );
    })(window, document, window.Beacon || function () {});

    window.Beacon('init', '5a40c9f3-34e6-4673-b7f7-2c7153411305');
    window.Beacon('config', { display: { zIndex: 9999 } });
    setIsLoaded(true);
  }, []);

  const destroy = useCallback(() => {
    if (window.Beacon) {
      window.Beacon('destroy');
      delete window.Beacon;
    }
  }, []);

  const open = useCallback(() => {
    if (window.Beacon) {
      window.Beacon('open');
    }
  }, []);

  const close = useCallback(() => {
    if (window.Beacon) {
      window.Beacon('close');
    }
  }, []);

  const toggle = useCallback(() => {
    if (window.Beacon) {
      window.Beacon('toggle');
    }
  }, []);

  const options = useMemo(
    () => ({
      onInit: init,
      onRemove: destroy,
    }),
    [init, destroy],
  );

  useScript('https://beacon-v2.helpscout.net', options);

  return {
    isLoaded,
    open,
    close,
    toggle,
    destroy,
  };
}
