import Image from 'next/image';
import { CSSProperties } from 'react';

import { Campaign } from '~/types/campaign';

function BigBoiBadge({ campaign }: { campaign: Campaign }) {
  const skuBadge: string | undefined = campaign?.campaign_config?.sku_logo;
  const bannerStyles = {} as CSSProperties;

  if (campaign) {
    bannerStyles.background = campaign.campaign_config.banner_color;
    bannerStyles.clipPath =
      'polygon(0% 0%, 100% 0, 93% 50%, 100% 100%, 0% 100%)';
  }

  return (
    <div
      className="absolute left-0 top-6 flex h-10 w-44 items-center justify-center py-2 pl-3 pr-6"
      style={bannerStyles}
    >
      <Image
        className="!relative !h-auto"
        src={skuBadge}
        alt={campaign.name}
        fill
      />
    </div>
  );
}

export default BigBoiBadge;
