import { Heading } from '@appsumo/dorado-react';
import Image from 'next/image';
import Link from 'next/link';
import { useMemo } from 'react';
import { Button } from '~/components/ui/Button';

/**
 * Thematic roadblock
 */
export const ThematicRoadblock = ({
  title,
  text,
  headline,
  backgroundColor,
  backgroundImage,
  componentImage,
  logoImage,
  ctaText,
  ctaLink,
  priority,
}: {
  /**
   * Title of collection
   */
  title: string;
  /**
   * Text to display (HTML allowed)
   */
  text: string;
  /**
   * Headline of the collection
   */
  headline?: string;
  /**
   * Background color
   */
  backgroundColor: string;
  /**
   * Image to display as full background of the card
   */
  backgroundImage: string;
  /**
   * Image for the main image
   */
  componentImage?: string;
  /**
   * Image for logo, badge, etc.
   */
  logoImage?: string;
  /**
   * CTA button text
   */
  ctaText: string;
  /**
   * CTA button link
   */
  ctaLink: string;
  /**
   * Priority for image loading
   */
  priority?: boolean;
}) => {
  const backgroundCard = useMemo(() => {
    if (backgroundImage) {
      return {
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      };
    }

    return { backgroundColor };
  }, [backgroundColor, backgroundImage]);

  return (
    <div className="relative">
      <Link
        href={ctaLink}
        className="absolute h-full w-full text-[0px] after:pointer-events-auto after:absolute after:inset-0 after:z-1 after:content-['']"
      >
        <span className="sr-only">{ctaText}</span>
      </Link>
      <div className="bg-email-capture" style={backgroundCard}>
        <div className="flex min-h-[380px] flex-col items-stretch gap-6 px-4 py-6 sm:mx-auto sm:max-w-[1206px] sm:flex-row sm:gap-0 sm:py-6">
          <div className="flex shrink-0 items-center sm:basis-1/2 sm:px-4">
            <Image
              src={`${componentImage}?width=380&height=215`}
              width={380}
              height={215}
              priority={priority}
              alt={title}
              unoptimized
              className="aspect-video w-full object-cover lg:hidden"
            />
            <Image
              src={`${componentImage}?width=560&height=315`}
              width={560}
              height={315}
              priority={priority}
              alt={title}
              unoptimized
              className="hidden aspect-video w-full object-cover lg:block"
            />
          </div>
          <div className="flex flex-col justify-center sm:basis-1/2 lg:px-10">
            <div className="flex flex-col gap-2">
              {logoImage && (
                <Image
                  src={`${logoImage}?width=180`}
                  alt={headline ?? 'headline image'}
                  width={180}
                  height={20}
                  priority={priority}
                  unoptimized
                />
              )}
              <Heading.H1
                className="leading-10"
                dangerouslySetInnerHTML={{ __html: headline }}
              />
              <div
                className="mt-2"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </div>
            <Button
              href={ctaLink}
              className="z-2 mt-6 inline-block w-full text-center sm:w-fit"
            >
              {ctaText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
