import Image from 'next/image';
import { forwardRef } from 'react';
import clsx from 'clsx';
import { AlignType, Button } from '@appsumo/dorado-react';
import { Arrow, AutoPlay } from '@egjs/flicking-plugins';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

import { Link } from '~/components/global/Link';
import { DealImage } from '~/components/product/DealImage';
import { DealDownloads } from '~/components/product/DealDownloads';
import { DealPrice } from '~/components/product/DealPrice';
import { DealRating } from '~/components/product/DealRating';
import SumoFlicking from '~/components/smartcollections/components/SumoFlicking';
import type { DealSkuCardType } from '~/components/sku/types';

import { SmartCollectionComponent } from './types';

/**
 * CarouselCell
 *
 * Adjusted the carousel to behave like mobile (the next carousel card is partial visible)
 * for anything less than lg breakpoint.  This is because we are using the email capture spotlight
 * as the "loading" placeholder while we wait for the user's collection. This is is much taller
 * than the regular spotlight and we need to take up the available vertical space.
 *
 * Notes:
 * - Text sizing, padding, margins, etc were adjusted to fit in the available space.
 */
export const CarouselCell = forwardRef<HTMLDivElement, any>(
  (
    {
      deal,
      priority = false,
      isPromo = false,
    }: {
      /**
       * Deal object
       */
      deal: DealSkuCardType;
      /**
       * If true, the image will be loaded immediately
       */
      priority: boolean;
      /**
       * If true, change styling of card
       */
      isPromo: boolean;
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={
          isPromo
            ? 'w-full px-5 sm:px-12'
            : 'w-full max-w-[700px] sm:w-[90%] sm:px-12 lg:w-full lg:max-w-full'
        }
      >
        <div className="flex w-full flex-col gap-y-3 sm:mx-auto sm:max-w-5xl lg:flex-row lg:items-center lg:gap-x-5">
          {/* carousel image */}
          <div className="relative lg:order-2">
            <Link
              className="relative flex h-full w-full lg:h-[298px] lg:w-[530px]"
              href={`${deal.get_absolute_url}`}
            >
              <DealImage
                deal={deal}
                width={530}
                height={298}
                priority={priority}
                isSpotlight={true}
              />
            </Link>
          </div>
          {/* carousel content */}
          <div
            className={`flex grow flex-col gap-y-3 ${
              isPromo ? 'text-white' : 'sm:text-white'
            } lg:order-1`}
          >
            <div>
              <span className="inline-block w-fit rounded bg-bolt px-2 py-1 text-xs text-white">
                Spotlight
              </span>
            </div>
            <Link
              href={`${deal.get_absolute_url}`}
              className="text-ellipsis text-2xl font-bold line-clamp-1 hover:underline md:text-3xl"
            >
              {deal.public_name}
            </Link>
            <div className="text-xs text-gray-400">Lifetime deal</div>
            <div
              className="my-1 text-sm line-clamp-2 md:line-clamp-3"
              dangerouslySetInnerHTML={{ __html: deal.card_description }}
            />
            {deal.is_free && (
              <DealDownloads
                downloads={deal.freebie_downloads_total}
                href={`${deal.get_absolute_url}`}
              />
            )}
            {!deal.is_free && (
              <DealRating
                averageRating={deal.deal_review?.average_rating}
                reviewCount={deal.deal_review?.review_count}
                className="mb-2"
                href={`${deal.get_absolute_url}#reviews`}
              />
            )}
            <DealPrice deal={deal} />
            <Button
              className="max-w-fit"
              href={`${deal.get_absolute_url}?ref=spotlight-learn-more`}
            >
              Learn more
            </Button>
          </div>
        </div>
      </div>
    );
  },
);

CarouselCell.displayName = 'CarouselCell';

const SpotlightArrows = ({
  nextArrowClassName,
  prevArrowClassName,
  isPromo,
}: {
  nextArrowClassName: string;
  prevArrowClassName: string;
  isPromo: boolean;
}) => {
  return (
    <>
      <span
        className={`${prevArrowClassName} absolute top-40 z-2 ${
          !isPromo ? 'left-1 lg:left-5 lg:ml-2' : 'left-1 lg:left-[-20px]'
        } hidden h-10 w-10 rounded-full bg-black-pearl text-gray-300 hover:cursor-pointer md:block`}
      >
        <span className="sr-only">Previous</span>
        <FontAwesomeIcon icon={faChevronLeft} className="mx-3 my-2 h-6" />
      </span>
      <span
        className={`${nextArrowClassName} absolute top-40 z-2 ${
          !isPromo ? 'right-1 lg:right-5 lg:mr-2' : 'right-1 lg:right-[-20px]'
        } hidden h-10 w-10 rounded-full bg-black-pearl text-gray-300 hover:cursor-pointer md:block`}
      >
        <span className="sr-only">Next</span>
        <FontAwesomeIcon icon={faChevronRight} className="mx-3 my-2 h-6" />
      </span>
    </>
  );
};

const PromoContent = ({
  component,
  title,
  backgroundColor,
  redirectUrl,
}: {
  component: SmartCollectionComponent;
  title: string;
  backgroundColor: string;
  redirectUrl: string;
}) => {
  const spotlightStyle = {
    ['--spotlight-background-color' as string]: backgroundColor,
    ['--background-image' as string]: `url(${component.backgroundImage})`,
    ['--mobile-background-image' as string]: `url(${
      component.mobileBackgroundImage || component.backgroundImage
    })`,
  } as React.CSSProperties;

  return (
    <div
      id={`spotlight-background-${component.id}}`}
      style={spotlightStyle}
      className="absolute top-0 left-0 h-[90%] w-full overflow-x-hidden bg-[var(--spotlight-background-color)] bg-[image:var(--mobile-background-image)]
        bg-cover py-10 px-5 pt-0 text-white sm:px-0 sm:pb-2 lg:bg-[image:var(--background-image)]"
    >
      <Link
        className="absolute top-0 left-0 h-full w-full"
        href={`${redirectUrl}`}
      >
        <span className="sr-only">{title}</span>
      </Link>
      <div className="flex h-80 flex-col items-center justify-center py-5 text-center">
        {component.promoImage ? (
          <Image
            src={component.promoImage}
            alt="promotion logo"
            width="440"
            height="35"
            className="my-5"
          />
        ) : (
          <h1>{title}</h1>
        )}
        <div
          className="z-1 max-w-2xl sm:text-lg"
          dangerouslySetInnerHTML={{ __html: component.text || '' }}
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
          role="none"
        />
      </div>
    </div>
  );
};

/**
 * Spotlight carousel component.

  * Set hard breakpoints for this spotlight to match the callout spotlight.  This is because we are
  * using a placeholder loading component (based on the callout spotlight) to reduce layout shift
  * while we wait for the user's collection to load.  The carousel was changed to reflect mobile for
  * anything less than the lg breakpoint (see CarouselCell component above).
  */

/**
 * Notes:
 * - Text sizing, padding, margins, etc were adjusted to fit in the available space.
 * - The placeholder loading component can be found on the home / index page's SmartCollection component.
 * - Some adjustments will probably need to be made for spotlight's with a background image (isPromo = true)
 */
export const Spotlight = ({
  deals,
  component,
  title,
  priority = false,
}: {
  /**
   * Array of deals from esbrowse
   */
  deals: DealSkuCardType[];
  /**
   * Smart Collection Component props
   */
  component: SmartCollectionComponent;
  /**
   * Smart Collection title
   */
  title: string;
  /**
   * If true, the first image will be loaded immediately
   */
  priority?: boolean;
}) => {
  const isPromo: boolean = !!component.backgroundImage;
  // Need ID's to differentiate the arrows for each spotlight since they are outside the Flicking component
  const nextArrowClassName = `flicking-next-spotlight-${component.id}`;
  const prevArrowClassName = `flicking-prev-spotlight-${component.id}`;
  let plugins = [] as Array<Arrow | AutoPlay>;

  if (typeof window !== 'undefined') {
    plugins = [
      new Arrow({
        parentEl: document?.body,
        moveByViewportSize: true,
        prevElSelector: `.${prevArrowClassName}`,
        nextElSelector: `.${nextArrowClassName}`,
      }),
      new AutoPlay({ duration: 5000, direction: 'NEXT', stopOnHover: true }),
    ];
  }

  const backgroundColor = component.backgroundColor || '#000';

  const containerClass = clsx(
    'relative w-full',
    isPromo
      ? 'pt-80'
      : 'h-[686px] sm:h-[778px] md:h-[804px] md:py-8 lg:h-[470px]',
  );

  /**
   * If making changes to the sizing, be aware of the placeholder component (see comment above)
   */
  return (
    <div className={containerClass} style={!isPromo ? { backgroundColor } : {}}>
      {isPromo && (
        <PromoContent
          component={component}
          title={title}
          backgroundColor={backgroundColor}
          redirectUrl={component.ctaLink || '/'}
        />
      )}
      <div
        className={`relative mx-auto h-full sm:py-10 ${
          isPromo ? 'max-w-[1000px] py-10' : 'w-full'
        } z-1 bg-white max-sm:px-4 max-sm:pb-4 sm:bg-transparent`}
        style={isPromo ? { backgroundColor } : {}}
      >
        <div className="md:px-3 lg:px-9">
          <SumoFlicking
            flickingProps={{
              plugins,
              align: AlignType.PREV,
              circular: true,
              resizeOnContentsReady: true,
              renderOnlyVisible: !isPromo,
            }}
          >
            {deals.map((deal, index) => (
              <CarouselCell
                key={deal.id}
                deal={deal}
                priority={priority && index === 0}
                isPromo={isPromo}
              />
            ))}
          </SumoFlicking>
        </div>
        <div className={deals.length > 1 ? '' : 'hidden'}>
          <SpotlightArrows
            {...{ nextArrowClassName, prevArrowClassName, isPromo }}
          />
        </div>
      </div>
    </div>
  );
};
