import Link from 'next/link';
import useSWR from 'swr';

import { getEsBrowseUrl } from '~/lib/browse/fetch';
import { PLAN_TYPES_SUFFIX } from '~/lib/util/constants';
import { currency } from '~/lib/format';

import { EsBrowseData } from '~/components/browse/types';

export interface IAlsoboughtDeal {
  dealId: number;
  textColor: string;
}

function BigBoiAlsoBoughtDeal({ dealId, textColor }: IAlsoboughtDeal) {
  const url = getEsBrowseUrl({}, { alsobought: dealId });
  const { data } = useSWR<EsBrowseData>(url);
  const [alsoBoughtDeal] = data?.deals ?? [];

  if (!alsoBoughtDeal) {
    return null;
  }

  let alsoBoughtPriceSuffix = null;

  if (PLAN_TYPES_SUFFIX.includes(alsoBoughtDeal?.default_plan?.plan_type)) {
    alsoBoughtPriceSuffix = alsoBoughtDeal.default_plan?.plan_type;
  }

  return (
    <div className="z-1 mt-6 max-w-xs max-lg:hidden">
      <p className={`font-header font-semibold ${textColor}`}>
        Customers also bought
      </p>
      <Link
        data-testid="also-bought-link"
        href={`/products/${alsoBoughtDeal.slug}`}
        className="group relative z-1 mt-2 inline-block min-w-[300px] rounded bg-white/20 p-4"
      >
        <div className={textColor}>
          <p
            data-testid="also-bought-title"
            className="mb-1 font-header text-sm font-semibold group-hover:underline"
          >
            {alsoBoughtDeal.public_name}
          </p>
          {alsoBoughtDeal.price === 0 && (
            <strong data-testid="also-bought-free">FREE</strong>
          )}
          {alsoBoughtDeal.price > 0 && (
            <div data-testid="also-bought-price">
              <span className="font-medium">
                {currency(alsoBoughtDeal.price)}
              </span>
              {alsoBoughtPriceSuffix && (
                <span
                  data-testid="also-bought-price-suffix"
                  className="text-sm"
                >
                  /{alsoBoughtPriceSuffix}
                </span>
              )}
            </div>
          )}
        </div>
      </Link>
    </div>
  );
}

export default BigBoiAlsoBoughtDeal;
