import { useEffect, useCallback, memo } from 'react';
import { PHASE_PRODUCTION_BUILD } from 'next/constants';
import clsx from 'clsx';

import {
  GetStaticPropsContextWithUserState,
  withStaticProps,
} from '~/lib/static';
import { fetchJson } from '~/lib/fetch';
import { useOneTap } from '~/lib/script';
import { useCollections } from '~/hooks/collections';
import { CollectionProps, ShopByCollectionProps } from '~/types/collections';
import {
  SmartCollectionComponent,
  CollectionType,
} from '~/components/smartcollections/components/types';

import { Loader } from '~/components/ui/Loader';
import { SpotlightCallout } from '~/components/smartcollections/components/SpotlightCallout';
import { Spotlight } from '~/components/smartcollections/components/Spotlight';
import { SpotlightCustom } from '~/components/smartcollections/components/SpotlightCustom';
import CountdownClock from '~/components/smartcollections/components/CountdownClock';
import { ThematicRoadblock } from '~/components/smartcollections/roadblocks/ThematicRoadblock';
import ProductCarousel from '~/components/smartcollections/components/ProductCarousel';
import { ShopByCollection } from '~/components/smartcollections/components/ShopByCollection';
import BigBoiCard from '~/components/smartcollections/components/BigBoiCard';
import { LazyLoadCollections } from '~/components/smartcollections/components/LazyLoadCollections';

import RisingStarsCollection from '~/public/collection/rising-stars-collection.png';
import AppSumoOriginalCollection from '~/public/collection/appsumo-originals-collection.png';
import FreebiesCollection from '~/public/collection/freebies-collection.png';
import AppSumoOriginals from '~/public/appsumo-originals.png';

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: 'blocking',
  };
}

export const getStaticProps = withStaticProps(
  async ({ userState, userDevice }: GetStaticPropsContextWithUserState) => {
    const isSSGBuild = process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD;
    // if this is a SSG build, we don't want to fetch data from the API
    // and let the first hit revalidate the data
    if (isSSGBuild) {
      return {
        props: {
          fallbackData: [],
        },
        revalidate: 1,
      };
    }

    // build the query string from the user state, verify that the user state exists or will error
    const scGroups = userState
      ? Object.keys(userState as any)
          .map(
            (k) =>
              `${encodeURIComponent(k)}=${encodeURIComponent(
                (userState as any)[k],
              )}`,
          )
          .join('&')
      : '';

    try {
      const collections = await fetchJson(
        `/api/smartcollections/personal/?include_deals=true&page=1&limit=4&use_cache=true&${scGroups}&user_device=${userDevice}`,
      );

      return {
        props: {
          fallbackData: collections,
          isAuthFromStaticProps: scGroups
            .toLowerCase()
            .includes('is_authenticated=true'),
        },
        revalidate: 240,
      };
    } catch (error) {
      console.error(error);
      return {
        props: {
          fallbackData: [],
          isAuthFromStaticProps: false,
        },
        revalidate: 1,
      };
    }
  },
);

// TODO: Hardcoded values for MVP only,
// must be removed once admin panel allows to create collections
const HardCodedShopByCollection = ({
  collection,
}: {
  collection: CollectionProps;
}) => {
  const collections = [
    {
      url: '/collections/rising-stars',
      title: 'Rising stars',
      subtitle: 'Noteworthy sellers',
      backgroundImage: RisingStarsCollection,
      variant: 'pinky',
    },
    {
      url: '/collections/appsumo-originals',
      title: 'Appsumo Originals',
      imgTitle: AppSumoOriginals,
      subtitle: 'Created by our team, for you',
      backgroundImage: AppSumoOriginalCollection,
      variant: 'bolt',
    },
    {
      url: '/collections/freebies',
      title: 'Freebies',
      subtitle: 'The best things in life are free!',
      backgroundImage: FreebiesCollection,
      variant: 'dollar',
    },
  ] as ShopByCollectionProps[];

  return (
    <ShopByCollection
      key={collection.id}
      title={collection.title}
      collections={collections}
    />
  );
};

/**
 * SmartCollection
 *
 * A placeholder was added to prevent flashing between different collections based on the user state.
 * The placeholder and the spotlight components have a set size to prevent as much layout shift as possible.
 * This only affects the spotlight callout and the regular callout.  Any size changes made to either
 * the placeholder or spotlight components should be made to the other component as well.
 */
const SmartCollection = ({
  collection,
  index,
  isAuthFromStaticProps,
  nextCollection,
}: {
  collection: CollectionProps;
  index: number;
  isAuthFromStaticProps: boolean;
  nextCollection?: CollectionProps;
}) => {
  const component = {
    id: collection.id,
    backgroundColor: collection.component_background_color,
    backgroundImage: collection.component_background,
    mobileBackgroundImage: collection.component_mobile_background,
    promoImage: collection.component_image,
    text: collection.component_promo,
    ctaText: collection.component_cta_text,
    ctaLink:
      collection.component_cta_link ?? `/collections/${collection.slug}/`,
    headerText: collection.component_header ?? '',
    bylineText: collection.component_byline ?? '',
    invertDefaultColors: collection.component_invert_default_colors,
  } as SmartCollectionComponent;
  const isAboveFold = index < 3;
  let carouselComponent = null;

  switch (collection.component_type) {
    case CollectionType.THEMATIC:
      return (
        <ThematicRoadblock
          key={collection.id}
          title={collection.title}
          backgroundImage={collection.component_background ?? ''}
          componentImage={collection.component_image ?? ''}
          text={collection.component_promo}
          headline={collection.component_headline ?? ''}
          backgroundColor={collection.component_background_color ?? ''}
          ctaText={collection.component_cta_text ?? ''}
          ctaLink={
            collection.component_cta_link ?? `/collections/${collection.slug}`
          }
          logoImage={collection.component_logo_image ?? ''}
          priority={isAboveFold}
        />
      );

    case CollectionType.SPOTLIGHT:
      carouselComponent = (
        <Spotlight
          key={collection.id}
          priority={isAboveFold}
          deals={collection.deals}
          component={component}
          title={collection.title}
        />
      );
      break;

    case CollectionType.SPOTLIGHT_CALLOUT:
      carouselComponent = (
        <SpotlightCallout
          key={collection.id}
          priority={isAboveFold}
          deals={collection.deals}
          component={component}
          isAuthFromStaticProps={isAuthFromStaticProps}
          handleOverflowContent={nextCollection?.component_overflow_content}
        />
      );
      break;

    case CollectionType.SPOTLIGHT_CUSTOM:
      carouselComponent = (
        <SpotlightCustom
          key={collection.id}
          priority={isAboveFold}
          deals={collection.deals}
          component={component}
          isAuthFromStaticProps={isAuthFromStaticProps}
          handleOverflowContent={nextCollection?.component_overflow_content}
        />
      );
      break;

    case CollectionType.PRODUCT_CAROUSEL:
      return (
        <ProductCarousel
          id={collection.id}
          title={collection.title}
          deals={collection.deals}
          href={`/collections/${collection.slug}`}
          priority={isAboveFold}
        />
      );

    case CollectionType.COUNTDOWN:
    case CollectionType.COUNTDOWN_CUSTOM:
      return (
        <CountdownClock
          key={collection.id}
          startDate={collection.start_date ?? ''}
          endDate={collection.end_date ?? ''}
          promoImage={collection.component_image}
          backgroundImage={collection.component_background}
          mobileBackgroundImage={collection.component_mobile_background}
          ctaLink={collection.component_cta_link}
          ctaText={collection.component_cta_text}
          more={`/collections/${collection.slug}`}
          isCustom={
            collection.component_type === CollectionType.COUNTDOWN_CUSTOM
          }
          backgroundColor={collection.component_background_color}
          timerLabelColor={
            (collection.component_enable_text_color &&
              collection.component_text_color) ||
            ''
          }
          headerText={collection.component_header}
          disableLazyLoad={collection.component_disable_lazy_load}
          isCompact={collection.component_display_compact_variant}
        />
      );

    case CollectionType.SHOP_BY:
      return <HardCodedShopByCollection collection={collection} />;

    case CollectionType.BIG_BOI:
      return (
        <div className="my-4 px-4 sm:px-8">
          <BigBoiCard
            deals={collection.deals}
            backgroundImage={collection.component_mobile_background}
            enableGradient={collection.component_enable_backgroud_gradient}
            overflowContent={collection.component_overflow_content}
            collectionTitle={collection.title}
            collectionSlug={collection.slug}
            priority={isAboveFold}
          />
        </div>
      );

    default:
      break;
  }

  return carouselComponent;
};

const MemoizedSmartCollections = memo(function MemoizedSmartCollections({
  collections,
  hasLoadedAllCollections,
  isAuthFromStaticProps,
  loadNextPage,
}: {
  collections: CollectionProps[];
  hasLoadedAllCollections: boolean;
  isAuthFromStaticProps: boolean;
  loadNextPage: () => void;
}) {
  const components = collections
    ?.map((collection, index) => {
      return (
        <div key={collection.id}>
          <SmartCollection
            index={index}
            collection={collection}
            isAuthFromStaticProps={isAuthFromStaticProps}
            nextCollection={collections[index + 1]}
          />
          {/*
              Only add the load lazy load trigger if we haven't loaded the collections and after the 2nd collection
              If for some reason there are not enough collections to fill above the fold on the page
              add the trigger above the fold.
            */}
          {(!hasLoadedAllCollections && collections.length < 3) || index > 1 ? (
            <LazyLoadCollections loadNextPage={loadNextPage} />
          ) : null}
        </div>
      );
    })
    .filter(Boolean);

  return <>{components}</>;
});

export default function HomePage({
  fallbackData,
  isAuthFromStaticProps,
  userDevice,
}: Readonly<{
  fallbackData: CollectionProps;
  isAuthFromStaticProps: boolean;
  userDevice: string;
}>) {
  useOneTap();
  const {
    collections,
    mutate,
    setSize,
    size,
    isLoading: isCollectionsLoading,
  } = useCollections(fallbackData, userDevice);
  const hasLoadedAllCollections = size > 1;
  const nextPageSize = size + 1;

  useEffect(() => {
    mutate();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadNextPage = useCallback(() => {
    if (hasLoadedAllCollections) {
      return;
    }

    setSize(nextPageSize);
  }, [hasLoadedAllCollections, nextPageSize, setSize]);

  // hasLoadedAllCollections will be true before querying next set of collections
  const showLoader =
    hasLoadedAllCollections && isCollectionsLoading ? (
      <div className="mt-2 flex w-full items-center justify-center sm:mt-6">
        <Loader size="4xl" className="sm:text-7xl" />
      </div>
    ) : null;

  const containerClass = clsx(
    'mx-auto flex w-full max-w-[2000px] grow flex-col',
    hasLoadedAllCollections &&
      [
        CollectionType.COUNTDOWN as string,
        CollectionType.COUNTDOWN_CUSTOM as string,
      ].includes(collections?.[collections.length - 1]?.component_type)
      ? ''
      : 'mb-10',
  );

  return (
    <div className={containerClass}>
      <MemoizedSmartCollections
        collections={collections}
        isAuthFromStaticProps={isAuthFromStaticProps}
        hasLoadedAllCollections={hasLoadedAllCollections}
        loadNextPage={loadNextPage}
      />
      {showLoader}
    </div>
  );
}
