import { useContext, useEffect, useMemo, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import clsx from 'clsx';

import { currency } from '~/lib/format';
import { getActiveCampaignForDeal } from '~/lib/campaigns/utils';
import {
  BIG_BOI_DEFAULT_RGB_BACKGROUND,
  PLAN_TYPES_SUFFIX,
} from '~/lib/util/constants';

import {
  colorNewShade,
  getCSSRGBValues,
  getTailwindContrastTextClass,
  rgbToHex,
} from '~/lib/util';

import { DealSkuCardType } from '~/components/sku';
import BigBoiBadge from './BigBoi/BigBoiBadge';
import BigBoiBanner from './BigBoi/BigBoiBanner';
import BigBoiAlsoBoughtDeal from './BigBoi/BigBoiAlsoBought';
import BigBoiDivisionBadge from './BigBoi/BigBoiDivisionBadge';
import BigBoiSkeleton from './BigBoi/BigBoiSkeleton';
import { Campaign } from '~/types/campaign';
import { GlobalContext } from '~/contexts/global';

interface IBigBoiCardProps {
  deals: DealSkuCardType[];
  backgroundImage?: string;
  enableGradient?: boolean;
  overflowContent?: boolean;
  className?: string;
  collectionTitle?: string;
  collectionSlug?: string;
  priority?: boolean;
}

function BigBoiCard({
  deals,
  backgroundImage,
  enableGradient = false,
  /** Allow negative margin to position element on top of previous sibiling */
  overflowContent = false,
  className,
  collectionTitle,
  collectionSlug,
  priority = false,
}: Readonly<IBigBoiCardProps>) {
  const [deal, setDeal] = useState<DealSkuCardType>();
  const {
    campaigns: { campaignConfigs },
  } = useContext(GlobalContext);

  const activeAutoCampaign: Campaign | null = useMemo(() => {
    if (!deal) {
      return null;
    }

    return getActiveCampaignForDeal({
      campaignConfigs,
      dealParticipation: deal.participation,
    });
  }, [deal, campaignConfigs]);

  useEffect(() => {
    if (!deals.length) {
      return;
    }

    const randomIndex = Math.floor(Math.random() * deals.length);

    if (!deal) {
      setDeal(deals[randomIndex]);
    }
  }, [deals, deal]);

  if (!deal) {
    return <BigBoiSkeleton overflowContent={overflowContent} />;
  }

  const dealNameHasMultipleWords = deal.public_name.split(' ').length > 1;
  let priceSuffix = null;

  if (PLAN_TYPES_SUFFIX.includes(deal.default_plan?.plan_type)) {
    priceSuffix = deal.default_plan?.plan_type;
  }

  const { value_enumeration: group } = deal.taxonomy.group || '';
  const { value_enumeration: category } = deal.taxonomy.category || '';

  const categoryUrl = group && category ? `/${group}/${category}` : '#';

  // default bg color: #1b1b1b (midnight-grey)
  const cardBackgroundColor =
    deal.rgb_background_color || BIG_BOI_DEFAULT_RGB_BACKGROUND;
  const [red, green, blue] = getCSSRGBValues(cardBackgroundColor);
  const hexColor = rgbToHex(red, green, blue);
  const constrastTextClass = getTailwindContrastTextClass(hexColor);
  const enableDarkMode = constrastTextClass === 'text-white';

  const backgroundStyle = (backgroundColor: string) => {
    if (backgroundImage) {
      return {
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      };
    }

    if (enableGradient) {
      return {
        background: `linear-gradient(225deg, ${backgroundColor} 50%, ${colorNewShade(
          hexColor,
          10,
        )} 100%)`,
      };
    }

    return { backgroundColor };
  };

  return (
    <div
      className={clsx(
        'relative z-2 m-auto max-w-[1206px] rounded-2xl p-6 lg:p-16',
        className,
        {
          'pt-20 lg:pt-20': activeAutoCampaign,
          '-mt-24': overflowContent,
        },
      )}
      style={backgroundStyle(cardBackgroundColor)}
    >
      {activeAutoCampaign && <BigBoiBadge campaign={activeAutoCampaign} />}

      <div className="grid grid-cols-1 gap-y-4 md:grid-cols-2 lg:gap-x-20">
        <div className="flex flex-col justify-between gap-y-8">
          <div>
            <div className={`${constrastTextClass}`}>
              <h2 className="font-header text-[22px] font-bold leading-[30px] line-clamp-4 md:text-[32px] md:leading-[42px]">
                {deal.card_description}
              </h2>
              <div className="mt-4 text-2xl font-medium leading-9 md:text-[32px]">
                {deal.price === 0 && <span className="uppercase">FREE</span>}
                {deal.price > 0 && (
                  <div>
                    <span>{currency(deal.price)}</span>
                    {priceSuffix && (
                      <span className="text-base font-normal leading-6 md:text-[22px] md:leading-7">
                        /{priceSuffix}
                      </span>
                    )}
                    {!!deal.original_price && (
                      <span
                        className={clsx(
                          'pl-3 text-base font-normal leading-6 line-through md:text-lg',
                          enableDarkMode ? 'text-iceberg' : 'text-grace',
                        )}
                      >
                        {currency(deal.original_price)}
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>

            <BigBoiBanner deal={deal} />
            <BigBoiAlsoBoughtDeal
              dealId={deal.id}
              textColor={constrastTextClass}
            />
          </div>

          <div className="hidden lg:block">
            <BigBoiDivisionBadge
              listingType={deal.listing_type}
              enableDarkMode={enableDarkMode}
            />
          </div>
        </div>

        <div className="flex max-w-xl flex-col lg:items-end">
          <Image
            data-testid="deal-image"
            src={`${deal.media_url}?width=865&height=486&aspect_ratio=16:9`}
            width={500}
            height={0}
            alt={deal.public_name}
            className="aspect-sku-card h-auto w-full object-cover shadow-lg"
            priority={priority}
            unoptimized
          />

          <div
            className={clsx(
              'relative mt-6 inline-flex gap-4 rounded bg-white/20 p-2 pr-4',
              dealNameHasMultipleWords ? 'w-full items-start' : 'items-center',
              !deal.favicon && 'pl-4',
            )}
          >
            {deal.favicon && (
              <div className="shrink-0 rounded bg-white p-2 shadow-sm">
                <Image
                  src={deal.favicon}
                  alt="favicon"
                  width={16}
                  height={16}
                  className="h-4 w-4"
                />
              </div>
            )}
            {/* For name with multiple words we need to trucate for long text */}
            <div
              className={clsx(
                dealNameHasMultipleWords
                  ? 'truncate'
                  : 'flex items-baseline gap-1',
                constrastTextClass,
              )}
            >
              <p className="truncate text-xl font-bold">{deal.public_name}</p>
              {deal.attributes?.category && (
                <span>
                  in
                  <Link
                    href={categoryUrl}
                    className="relative z-1 ml-1 hover:underline"
                  >
                    {deal.attributes.category[0]}
                  </Link>
                </span>
              )}
            </div>
          </div>

          {collectionTitle && collectionSlug && (
            <Link
              data-testid="collection-link"
              href={`/collections/${collectionSlug}`}
              className={`z-1 mt-4 hover:underline lg:mt-6 ${constrastTextClass}`}
            >
              Shop {collectionTitle}
            </Link>
          )}
        </div>

        <div className="lg:hidden">
          <BigBoiDivisionBadge
            listingType={deal.listing_type}
            enableDarkMode={enableDarkMode}
          />
        </div>
      </div>

      <Link
        data-testid="deal-link"
        href={`/products/${deal.slug}`}
        className='after:absolute after:inset-0 after:content-[""]'
      >
        <span className="sr-only">See details</span>
      </Link>
    </div>
  );
}

export default BigBoiCard;
