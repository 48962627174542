import {
  Button,
  CountdownClock as CountdownClockComponent,
} from '@appsumo/dorado-react';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { CSSProperties, memo, useEffect, useRef, useState } from 'react';
import { useIntersectionObserver } from 'usehooks-ts';

interface DynamicElements {
  collectionButton: JSX.Element | null;
  ctaButton: JSX.Element | null;
  headerTextElement: JSX.Element;
}

interface DynamicClasses {
  containerClass: string;
  labelColorClass: string;
}

/**
 * Moved the following functions from the original component to the top of the file to reduce
 * cognitive complexity lint warnings and make it easier to read.
 */
const hasStarted = (
  end: string,
  start?: string,
  currentTime?: Date,
): boolean => {
  if (!currentTime) return false;

  const endDate = new Date(end);

  if (start) {
    const startDate = new Date(start);
    if (currentTime >= startDate && currentTime < endDate) {
      return true;
    }
  } else if (currentTime < endDate) {
    return true;
  }

  return false;
};

const hasEnded = (end: string, currentTime?: Date): boolean => {
  if (!currentTime) return true;

  const endDate = new Date(end);
  return currentTime >= endDate;
};

const getDynamicElements = (
  started: boolean,
  ended: boolean,
  more: string,
  isCustom?: boolean,
  ctaLink?: string,
  ctaText?: string,
  headerText?: string,
  isCompact?: boolean,
): DynamicElements => {
  const direction = started || ended ? 'Ends' : 'Starts';

  const collectionButton =
    (started && !isCustom && <Button href={more}>View all deals</Button>) ||
    null;

  const ctaButton =
    (ctaLink && (
      <Link
        className="rounded-full border-2 border-transparent bg-midnight px-6 py-2 font-header font-bold text-white hover:border-gravel
        hover:bg-gravel focus:border-black-pearl focus:bg-gravel active:border-black-pearl active:bg-black-pearl"
        href={ctaLink}
        target="_blank"
      >
        {ctaText}
      </Link>
    )) ||
    null;

  const headerTextClasses = !isCompact
    ? 'text-xl font-semibold'
    : 'font-bold font-header text-[26px] lg:text-[32px]';
  const headerTextElement =
    isCustom && headerText ? (
      <span
        className={headerTextClasses}
        dangerouslySetInnerHTML={{ __html: headerText }}
      />
    ) : (
      <span className={headerTextClasses}>{direction} in</span>
    );

  return {
    collectionButton,
    ctaButton,
    headerTextElement,
  };
};

const getDynamicClasses = (
  isCustom?: boolean,
  backgroundImage?: string,
  isCompact?: boolean,
): DynamicClasses => {
  const containerClass = clsx(
    // Is not a `Countdown custom` component (from admin).
    !isCustom && 'bg-cover py-10 max-sm:mb-4 sm:pt-14 sm:pb-20',
    // Is a `Countdown custom` component (from admin)
    isCustom && (isCompact ? 'py-4' : 'py-6 lg:py-10'),
    backgroundImage &&
      'bg-[image:var(--mobile-background-image)] lg:bg-[image:var(--background-image)]',
    isCustom && 'bg-[var(--countdown-container-bg-color)]',
  );
  const labelColorClass = isCustom
    ? 'text-[var(--countdown-label-text-color)]'
    : '';

  return {
    containerClass,
    labelColorClass,
  };
};

export default memo(function CountdownClock({
  ctaLink,
  ctaText,
  promoImage,
  backgroundImage = '',
  mobileBackgroundImage = '',
  startDate,
  endDate,
  more,
  isCustom,
  backgroundColor = '',
  timerLabelColor = '',
  headerText,
  disableLazyLoad,
  isCompact,
}: {
  ctaLink?: string;
  ctaText?: string;
  // Promotion image
  promoImage?: string;
  // Background image for promotion
  backgroundImage?: string;
  // Separate mobile background image
  mobileBackgroundImage?: string;
  /**
   * Start date of countdown clock
   */
  startDate?: string;
  /**
   * End date of countdown clock
   */
  endDate: string;
  /**
   * Link to the collection page
   */
  more: string;
  isCustom?: boolean;
  backgroundColor?: string;
  timerLabelColor?: string;
  headerText?: string;
  disableLazyLoad: boolean;
  isCompact: boolean;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const [currentTime, setCurrentTime] = useState<Date>();
  const container = useIntersectionObserver(ref, { rootMargin: '150px 0px' });
  const isVisible = disableLazyLoad || !!container?.isIntersecting;

  // Prevent hydration issues
  useEffect(() => {
    setCurrentTime(new Date());
  }, []);

  // Nothing to render if no dates are given
  if (!startDate && !endDate) return null;

  const started = hasStarted(endDate, startDate, currentTime);
  const ended = hasEnded(endDate, currentTime);

  // Which time should we show
  let deadline: string | undefined = undefined;
  if (!started && !ended) {
    deadline = startDate;
  } else if (started || ended) {
    deadline = endDate;
  }

  const wrapperVariables = {
    ...(backgroundColor && {
      '--countdown-container-bg-color': backgroundColor,
    }),
    ...(timerLabelColor && { '--countdown-label-text-color': timerLabelColor }),
    ...(backgroundImage && { '--background-image': `url(${backgroundImage})` }),
    ...((mobileBackgroundImage || backgroundImage) && {
      '--mobile-background-image': `url(${
        mobileBackgroundImage ?? backgroundImage
      })`,
    }),
  } as CSSProperties;

  const { collectionButton, ctaButton, headerTextElement } = getDynamicElements(
    started,
    ended,
    more,
    isCustom,
    ctaLink,
    ctaText,
    headerText,
    isCompact,
  );
  const { containerClass, labelColorClass } = getDynamicClasses(
    isCustom,
    backgroundImage,
    isCompact,
  );

  return (
    <div
      ref={ref}
      className={containerClass}
      style={isVisible ? wrapperVariables : {}}
      data-testid="countdown-clock-container"
    >
      <div className="mx-auto w-fit">
        {promoImage && (
          <Image
            className="mx-auto"
            src={promoImage}
            alt="promotion logo"
            loading="lazy"
            height={100}
            width={440}
          />
        )}
        <div
          className={clsx(
            'flex items-center',
            isCompact ? 'flex-row gap-x-4' : 'flex-col',
          )}
        >
          {headerTextElement}
          {/* Countdown clock */}
          {deadline ? (
            <CountdownClockComponent
              deadline={deadline}
              labelColorClass={labelColorClass}
              isCompact={isCompact}
            />
          ) : (
            <span>a bit!</span>
          )}
        </div>
        {/* Buttons */}
        {!!collectionButton ||
          (!!ctaButton && (
            <div className="mt-4 flex flex-row items-center justify-center gap-1">
              {collectionButton}
              {ctaButton}
            </div>
          ))}
      </div>
    </div>
  );
});
