import Image from 'next/image';

import AppSumoSelectInvertBadge from '~/public/appsumo-select-bg-green.svg';
import AppsumoSelectBadge from '~/public/appsumo-select-bg-white.svg';
import AppsumoOriginalInvertBadge from '~/public/appsumo-original-bg-blue.svg';
import AppsumoOriginalBadge from '~/public/appsumo-original-bg-white.svg';
import AppsumoPlusInvertedBadge from '~/public/appsumo-plus-bg-red.svg';
import AppsumoPlusBadge from '~/public/appsumo-plus-bg-white.svg';

import { DealListingType } from '~/components/sku/types';

export interface IBigBoiDivisionBadgeProps {
  listingType: DealListingType;
  enableDarkMode: boolean;
}

function BigBoiDivisionBadge({
  listingType,
  enableDarkMode,
}: IBigBoiDivisionBadgeProps) {
  let badgeSVG = '';
  let altText = '';

  if (listingType === DealListingType.SELECT) {
    badgeSVG = enableDarkMode ? AppSumoSelectInvertBadge : AppsumoSelectBadge;
    altText = 'AppSumo Select';
  }

  if (listingType === DealListingType.ORIGINAL) {
    badgeSVG = enableDarkMode
      ? AppsumoOriginalInvertBadge
      : AppsumoOriginalBadge;
    altText = 'AppSumo Original';
  }

  if (listingType === DealListingType.PLUS_EXCLUSIVE) {
    badgeSVG = enableDarkMode ? AppsumoPlusInvertedBadge : AppsumoPlusBadge;
    altText = 'AppSumo Plus';
  }

  if (!badgeSVG) {
    return null;
  }

  return (
    <Image
      src={badgeSVG}
      alt={altText}
      width={enableDarkMode ? 100 : 120}
      height={11}
    />
  );
}

export default BigBoiDivisionBadge;
