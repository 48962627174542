import { DealSkuCardType } from '~/components/sku';
import { useGetSkuBannerDetails } from '~/lib/browse/hooks';

function BigBoiBanner({ deal }: { deal: DealSkuCardType }) {
  const { bannerText, bannerColor } = useGetSkuBannerDetails(deal);

  if (!bannerText) {
    return null;
  }

  return (
    <div
      className={`inline-block rounded ${bannerColor} mt-4 py-1.5 px-4 text-center text-white`}
    >
      {bannerText}
    </div>
  );
}

export default BigBoiBanner;
