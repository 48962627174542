import { useMemo } from 'react';
import Image from 'next/image';
import clsx from 'clsx';
import { AlignType, Button, MoveType } from '@appsumo/dorado-react';
import { Arrow, AutoPlay } from '@egjs/flicking-plugins';
import { ViewportSlot } from '@egjs/react-flicking';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { SmartCollectionComponent } from './types';
import type { DealSkuCardType } from '~/components/sku/types';
import useUser from '~/lib/user';
import { GA4FormContentValue } from '~/lib/util/constants';

import SwoopingArrow from '~/public/spotlight/swooping-arrow.svg';
import SwoopingArrowSmall from '~/public/spotlight/swooping-arrow-small.svg';
import { SpotlightCard } from '~/components/sku/SpotlightCard';
import { EmailCapture } from '~/components/global/EmailCapture';
import SumoFlicking from '~/components/smartcollections/components/SumoFlicking';

enum SpotlightType {
  default = 'default',
  unauthenticated = 'unauthenticated',
}

const CalloutHeader = ({
  component,
  spotlightType,
}: {
  component: SmartCollectionComponent;
  spotlightType: SpotlightType;
}) => {
  // The email capture takes up a lot more space so limit the lines to 2
  const headerClassName = clsx(
    'font-header text-[28px] font-bold leading-[32px] sm:text-[40px] sm:leading-[52px]',
    spotlightType === SpotlightType.unauthenticated
      ? 'line-clamp-2'
      : 'line-clamp-3',
  );
  const defaultHeader = (
    <h2 className={headerClassName}>
      Discover <span className="text-dollar">essential tools</span> for your
      business
    </h2>
  );
  const defaultUnauthenticatedHeader = (
    <h2 className={headerClassName}>Never pay full price for software again</h2>
  );

  if (component.headerText) {
    return (
      <div
        className={headerClassName}
        dangerouslySetInnerHTML={{ __html: component.headerText }}
      />
    );
  }

  return spotlightType === SpotlightType.default
    ? defaultHeader
    : defaultUnauthenticatedHeader;
};

const CalloutContent = ({
  component,
  spotlightType,
}: {
  component: SmartCollectionComponent;
  spotlightType: SpotlightType;
}) => {
  const contentClassname = 'sm:text-lg line-clamp-3';
  const defaultContent = (
    <p className={contentClassname}>
      Sick of subscriptions? Buy software that&apso;s yours for life on AppSumo.
    </p>
  );
  const defaultUnauthenticatedContent = (
    <p className={contentClassname}>
      Sign up to get the hottest software deals sent straight to your inbox and
      get 10% off your first order!
    </p>
  );

  if (component.text) {
    return (
      <div
        className={contentClassname}
        dangerouslySetInnerHTML={{ __html: component.text }}
      />
    );
  }

  return spotlightType === SpotlightType.default
    ? defaultContent
    : defaultUnauthenticatedContent;
};

const UnauthenticatedCallout = ({
  component,
}: {
  component: SmartCollectionComponent;
}) => {
  const inputLabelClasses = clsx(
    'font-header font-semibold',
    component.invertDefaultColors ? 'text-black-pearl' : 'text-white',
  );

  return (
    <div className="flex shrink-0 flex-col gap-y-4 px-4 text-center sm:max-w-lg sm:text-left">
      <CalloutHeader
        component={component}
        spotlightType={SpotlightType.unauthenticated}
      />
      <CalloutContent
        component={component}
        spotlightType={SpotlightType.unauthenticated}
      />
      <EmailCapture
        componentName="spotlight-email-capture"
        content={GA4FormContentValue.spotlightHome10off}
        className="flex flex-col gap-y-4 text-left md:max-w-[400px]"
        submitText={component.ctaText ?? 'Get 10% Off'}
        inputLabelClasses={inputLabelClasses}
      />
    </div>
  );
};

export const Callout = ({
  component,
}: {
  component: SmartCollectionComponent;
}) => {
  const buttonText = component.ctaText
    ? component.ctaText
    : 'View recommended deals';
  const buttonLink = component.ctaLink
    ? component.ctaLink
    : '/collections/for-you/';

  return (
    <div className="flex w-full shrink-0 flex-col gap-y-4 px-4 text-center sm:w-auto sm:max-w-lg sm:text-left lg:h-full lg:justify-center lg:px-4">
      <CalloutHeader
        component={component}
        spotlightType={SpotlightType.default}
      />
      <CalloutContent
        component={component}
        spotlightType={SpotlightType.default}
      />
      <div className="flex flex-col gap-y-4 text-left">
        <Button href={buttonLink} className="w-full text-center sm:w-fit">
          {buttonText}
        </Button>
      </div>
      {component.bylineText && (
        <div className="relative mt-auto w-full max-w-[471px] flex-col border-t border-spruce pt-4 sm:border-0 sm:bg-forest sm:px-4 sm:py-2">
          {/* Flare images */}
          <Image
            src={SwoopingArrow}
            alt="Callout Flare"
            className="absolute top-[-62px] right-[-75px] hidden align-middle lg:flex"
          />
          <Image
            src={SwoopingArrowSmall}
            alt="Callout Flare"
            className="absolute top-[-12px] right-[-60px] hidden align-middle md:flex lg:hidden"
          />
          {/* Byline text optionally shown via /admin */}
          <div
            className="text-base line-clamp-3 sm:text-lg"
            dangerouslySetInnerHTML={{ __html: component.bylineText ?? '' }}
          />
        </div>
      )}
    </div>
  );
};

/**
 * SpotlightCallout
 *
 * Set hard breakpoints for this callout spotlight to match the regular spotlight. This is because we are
 * using a placeholder loading component (based on the callout spotlight) to reduce layout shift
 * while we wait for the user's collection to load. We are currently using the email capture component
 * version of this for anon users. This version uses a lot of space so the regular spotlight has
 * been adjusted to match.
 *
 * Notes:
 * - Text sizing, padding, margins, etc were adjusted to fit in the available space.
 * - The placeholder loading component can be found on the home / index page's SmartCollection component.
 * - "isPromo" was removed to allow a bg color to show while the bg image loads.
 */

export const SpotlightCustom = ({
  deals,
  component,
  priority = false,
  isAuthFromStaticProps,
  handleOverflowContent = false,
}: {
  /**
   * Array of deals from esbrowse
   */
  deals: DealSkuCardType[];
  /**
   * Smart Collection Component props
   */
  component: SmartCollectionComponent;
  /**
   * If true, the first image will be loaded immediately
   */
  priority?: boolean;
  isAuthFromStaticProps: boolean;
  /**
   * If true, it means the next collection will overflow the spotlight,
   * so we need to adjust the height of the spotlight to prevent the overflow
   */
  handleOverflowContent?: boolean;
}) => {
  const plugins = [
    new Arrow({
      prevElSelector: '.flicking-prev',
      nextElSelector: '.flicking-next',
    }),
    new AutoPlay({ duration: 5000, direction: 'NEXT', stopOnHover: true }),
  ];

  const { user, isLoading } = useUser();

  const spotlightStyle = {
    ['--background-image' as string]: `url(${component.backgroundImage})`,
    ['--mobile-background-image' as string]: `url(${
      component.mobileBackgroundImage || component.backgroundImage
    })`,
  } as React.CSSProperties;

  const overflowContentClasses: string = useMemo(() => {
    if (!handleOverflowContent) {
      // default height
      return 'h-[686px] py-4 sm:h-[778px] md:h-[804px] md:py-6 lg:h-[470px]';
    }

    // if any deal has end date (card will increase size by adding countdown element),
    // so use a larger height
    if (deals.some((deal) => deal.active_timer && deal.dates?.end_date)) {
      return 'h-[826px] pt-12 sm:h-[946px] lg:h-[596px]';
    }

    return 'h-[726px] pt-12 sm:h-[846px] lg:h-[546px]';
  }, [handleOverflowContent, deals]);

  /**
   * If making changes to the sizing, be aware of the placeholder component (see comment above)
   * Removed the conditional bg color for "isPromo" to allow bg color to show while bg image loads
   * isAuthFromStaticProps will have the previous auth state while we wait for the user data to load
   */
  return (
    <div
      className={clsx(
        'flex flex-col overflow-hidden bg-[var(--spotlight-background-color)] bg-[image:var(--mobile-background-image)] bg-cover text-white lg:bg-[image:var(--background-image)]',
        overflowContentClasses,
      )}
      style={{
        ...spotlightStyle,
        backgroundColor: component.backgroundColor ?? '#000',
      }}
    >
      <div className="flex h-auto flex-col items-center gap-y-4 sm:items-start sm:gap-x-[70px] lg:ml-[100px] lg:flex-row">
        {user?.is_authenticated || (isLoading && isAuthFromStaticProps) ? (
          <Callout component={component} />
        ) : (
          <UnauthenticatedCallout component={component} />
        )}
        <div className="w-full pl-4">
          <SumoFlicking
            flickingProps={{
              plugins,
              align: AlignType.PREV,
              circular: true,
              moveType: MoveType.FREE_SCROLL,
            }}
          >
            {deals.map((deal, index) => (
              <SpotlightCard
                key={deal.id}
                deal={deal}
                priority={priority && index === 0}
                hideBanner
              />
            ))}
            <ViewportSlot>
              <div className="mt-4 flex gap-x-2 max-sm:hidden">
                <button className="flicking-prev h-8 w-8 rounded-full bg-dollar text-black-pearl">
                  <span className="sr-only">Previous</span>
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <button className="flicking-next h-8 w-8 rounded-full bg-dollar text-black-pearl">
                  <span className="sr-only">Next</span>
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            </ViewportSlot>
          </SumoFlicking>
        </div>
      </div>
    </div>
  );
};
