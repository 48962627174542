import clsx from 'clsx';

function BigBoiSkeleton({ overflowContent }: { overflowContent: boolean }) {
  return (
    <div
      className={clsx(
        'm-auto max-w-[1206px] rounded-2xl bg-midnight p-6 lg:p-16',
        {
          '-mt-24': overflowContent,
        },
      )}
    >
      <div className="grid animate-pulse grid-cols-1 gap-y-4 md:grid-cols-2 lg:gap-x-20">
        <div className="flex flex-col justify-between gap-y-8">
          <div>
            <div>
              <div className="h-6 bg-gray-200 dark:bg-gray-300"></div>
              <div className="mt-4 h-6 bg-gray-200 dark:bg-gray-300"></div>
              <div className="mt-4 h-8 w-32 bg-gray-200 dark:bg-gray-300"></div>
            </div>
          </div>
        </div>

        <div className="flex max-w-xl flex-col lg:items-end">
          <div className="h-56 w-full bg-gray-200 dark:bg-gray-300"></div>
          <div className="mt-6 h-8 w-4/5 bg-gray-200 dark:bg-gray-300"></div>
          <div className="mt-4 h-4 w-20 bg-gray-200 dark:bg-gray-300"></div>
        </div>
      </div>

      <div className="h-8 w-16 bg-gray-200 dark:bg-gray-300"></div>
    </div>
  );
}

export default BigBoiSkeleton;
