import { useCallback, useEffect, useMemo } from 'react';
import getConfig from 'next/config';
import { useRouter } from 'next/router';

import useUser from '~/lib/user';
import useScript from './hook';

const { publicRuntimeConfig } = getConfig();

const { GOOGLE_DATA_CLIENT_ID } = publicRuntimeConfig;

interface PromptType {
  isSkippedMoment: () => boolean;
}

// Declare the global interface for the window object
declare global {
  interface Window {
    google: {
      accounts: {
        id: {
          initialize: (options: { client_id: string }) => void;
          cancel: () => void;
          prompt: (data?: (notification: PromptType) => void) => void;
        };
      };
    };
  }
}

export default function useOneTap() {
  const { user, isLoading } = useUser();
  const router = useRouter();

  const onInit = useCallback(() => {
    if (
      typeof window === 'undefined' ||
      user?.is_authenticated ||
      typeof user === 'undefined' || // anon user will be null
      isLoading ||
      document.getElementById('g_id_onload')
    )
      return;

    const oneTapEl = document.createElement('div');
    oneTapEl.setAttribute('id', 'g_id_onload');

    // docs here: https://developers.google.com/identity/gsi/web/guides/fedcm-migration
    oneTapEl.setAttribute('use_fedcm_for_prompt', 'true'); // opt-in before deadline to ensure smooth transition

    oneTapEl.setAttribute(
      'data-login_uri',
      `${process.env.NEXT_PUBLIC_DJANGO_PUBLIC_URL}/api/users/login/google/`,
    );
    oneTapEl.setAttribute('data-itp_support', 'true');
    oneTapEl.setAttribute('data-client_id', GOOGLE_DATA_CLIENT_ID);
    oneTapEl.setAttribute('data-redirect_uri', `${router.asPath}`);
    oneTapEl.setAttribute('class', 'w-0 h-0 invisible');

    document.body.appendChild(oneTapEl);
  }, [router.asPath, user, isLoading]);

  const onRemove = useCallback(() => {
    document.getElementById('g_id_onload')?.remove();
  }, []);

  const options = useMemo(
    () => ({
      onInit,
      onRemove,
    }),
    [onRemove, onInit],
  );

  useEffect(() => {
    // If user is logged in, and one tap is showing, hide it (logging in on a different page)
    if (user?.is_authenticated) {
      onRemove();
    }
  }, [user?.is_authenticated, onRemove]);

  useScript(
    GOOGLE_DATA_CLIENT_ID && !user?.is_authenticated && !isLoading
      ? 'https://accounts.google.com/gsi/client'
      : null,
    options,
  );
}
