import { forwardRef } from 'react';

import { CountdownTimer } from '@appsumo/dorado-react';

import { Link } from '~/components/global/Link';
import { DealPrice } from '~/components/product/DealPrice';
import { DealImage } from '~/components/product/DealImage';
import type { DealSkuCardType } from '~/components/sku/types';

export const SpotlightCard = forwardRef<HTMLDivElement, any>(
  (
    {
      deal,
      priority = false,
      target = '_self',
      hideBanner = false,
    }: {
      /**
       * Deal object
       */
      deal: DealSkuCardType;
      /**
       * If true, the image will be loaded immediately
       */
      priority: boolean;
      /**
       * Target for the link
       * @default '_self'
       */
      target?: '_self' | '_blank';
      hideBanner?: boolean;
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className="relative mr-4 flex w-[300px] flex-col gap-y-2 rounded border-t-4 border-t-dollar bg-forest p-4"
      >
        <Link
          href={`${deal.get_absolute_url}`}
          target={target}
          className="absolute h-full w-full text-[0px] after:pointer-events-auto after:absolute after:inset-0 after:z-[1] after:content-['']"
        >
          <span className="sr-only">{deal.public_name}</span>
        </Link>
        <div className="grow">
          <p className="line-clamp-2 sm:line-clamp-3">
            {deal.card_description}
          </p>
        </div>
        <h5 className="font-header text-sm font-bold">{deal.public_name}</h5>
        <div className="flex items-baseline gap-x-4">
          <DealPrice deal={deal} isCallout />
        </div>
        {deal.active_timer && deal.dates?.end_date && (
          <div className="flex">
            <CountdownTimer datetime={deal.dates.end_date} style="red-pill" />
          </div>
        )}
        <div>
          <DealImage
            deal={deal}
            width={252}
            height={142}
            priority={priority}
            hideBanner={hideBanner}
          />
        </div>
      </div>
    );
  },
);

SpotlightCard.displayName = 'SpotlightCard';
