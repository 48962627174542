import Image from 'next/image';
import Link from 'next/link';
import { useMemo } from 'react';
import clsx from 'clsx';
import { Heading } from '@appsumo/dorado-react';

import { shopByCardConfig } from '~/constants/collections';
import { ShopByCollectionProps } from '~/types/collections';

import RightArrow from '~/public/right-arrow.png';

function ShopByCard({
  title,
  imgTitle,
  subtitle,
  url,
  backgroundImage,
  variant,
  className,
}: ShopByCollectionProps) {
  return (
    <Link
      className={clsx(
        'group relative h-72 min-w-[80vw] grow snap-start overflow-hidden rounded-lg text-white md:min-w-[385px] lg:min-w-[initial]',
        'before:absolute before:inset-0 before:brightness-[60%] before:content-[""]',
        variant === 'pinky' && 'before:bg-pinky',
        variant === 'bolt' && 'before:bg-bolt',
        variant === 'dollar' && 'before:bg-dollar',
        className,
      )}
      href={url}
    >
      <Image
        className="absolute left-0 top-0 w-full"
        src={backgroundImage}
        alt=""
        role="presentation"
        width={1280}
        height={1280}
      />

      <div
        className={clsx(
          'absolute inset-0 z-1 bg-gradient-to-t via-75 brightness-[55%] transition group-hover:brightness-[45%]',
          variant === 'pinky' && 'from-pinky',
          variant === 'bolt' && 'from-bolt',
          variant === 'dollar' && 'from-dollar',
        )}
      />

      <div className="absolute bottom-7 left-0 z-2 flex w-full items-center justify-between px-6">
        <div>
          <h3 className="font-header text-2xl font-bold">
            {imgTitle && (
              <>
                <span className="sr-only">{title}</span>
                <Image
                  className="pb-1.5"
                  src={imgTitle}
                  role="presentation"
                  alt=""
                />
              </>
            )}

            {!imgTitle && title}
          </h3>
          {subtitle && <p className="text-sm">{subtitle}</p>}
        </div>

        <span
          className={clsx(
            'relative flex h-8 w-8 shrink-0 items-center justify-center overflow-hidden rounded-full transition-transform group-hover:-translate-y-1 lg:h-10 lg:w-10',
            'before:absolute before:inset-0 before:brightness-[80%] before:content-[""]',
            variant === 'pinky' && 'before:bg-pinky',
            variant === 'bolt' && 'before:bg-bolt',
            variant === 'dollar' && 'before:bg-dollar',
          )}
        >
          <span className="sr-only">See more</span>
          <Image
            src={RightArrow}
            role="presentation"
            alt=""
            className="relative z-1"
          />
        </span>
      </div>
    </Link>
  );
}

export function ShopByCardV2({
  title,
  imgTitle,
  subtitle,
  url,
  backgroundImage,
  lastCard,
  variant,
  className,
  cardType,
}: Readonly<ShopByCollectionProps>) {
  const config = variant ? shopByCardConfig[variant] : shopByCardConfig.default;
  const tallCard = cardType === 'tall';
  return (
    <Link
      className={clsx(
        'group relative flex min-w-[70vw] flex-1 overflow-hidden rounded-lg text-white',
        tallCard
          ? 'h-64 sm:min-w-[300px] lg:h-[345px] lg:min-w-[initial]'
          : 'h-72 md:min-w-[385px] lg:min-w-[initial]',
        lastCard && 'mr-4 md:mr-8 lg:mr-0',
        config.bg,
        className,
      )}
      href={url}
      data-testid="shop-by-card"
    >
      <Image
        className={clsx(
          'absolute z-0 h-80 w-auto max-w-none',
          tallCard ? '-left-36 -top-20 lg:h-80' : 'lg:h-auto',
        )}
        src={backgroundImage}
        alt={title}
        role="presentation"
        width={1280}
        height={1280}
        data-testid="shop-by-card-bg-image"
      />
      <div
        className={clsx(
          'absolute z-1 h-full w-full bg-gradient-to-t via-75 to-transparent pb-4',
          'group-hover:brightness-[80%]',
          config.gradient,
        )}
        data-testid="shop-by-card-gradient"
      />

      <div className="z-2 flex w-full pb-4">
        <div
          className={clsx(
            'mt-auto flex flex-row gap-4 px-4',
            tallCard && 'lg:flex-col',
          )}
          data-testid="shop-by-card-content"
        >
          <div>
            <Heading.H2 className="mb-1 text-white">
              {imgTitle && (
                <>
                  <span className="sr-only">{title}</span>
                  <Image
                    className="pb-1.5"
                    src={imgTitle}
                    role="presentation"
                    alt=""
                  />
                </>
              )}

              {!imgTitle && title}
            </Heading.H2>
            {subtitle && <p className="text-sm">{subtitle}</p>}
          </div>
          <span
            className={clsx(
              'my-auto flex h-8 w-8 shrink-0 items-center justify-center overflow-hidden rounded-full transition-transform group-hover:-translate-y-1 lg:h-10 lg:w-10',
              config.buttonBg,
            )}
            data-testid="shop-by-card-seemore"
          >
            <span className="sr-only">See more</span>
            <Image
              src={RightArrow}
              role="presentation"
              alt=""
              className="z-1"
            />
          </span>
        </div>
      </div>
    </Link>
  );
}

export const ShopByCollection = ({
  title,
  subtitle,
  collections,
  cardVariant = 'default',
  wrapperClassName,
}: {
  title: string;
  subtitle?: string;
  collections: ShopByCollectionProps[];
  cardVariant?: 'default' | 'tall';
  wrapperClassName?: string;
}) => {
  // TODO: Remove this when we have all collections migrated to v2
  const originalCardVariant = cardVariant === 'default';
  const collectionCards = useMemo(() => {
    return collections.map((collection, index) =>
      originalCardVariant ? (
        <ShopByCard key={index} {...collection} />
      ) : (
        <ShopByCardV2
          key={index}
          {...collection}
          cardType={cardVariant}
          lastCard={index === collections.length - 1}
        />
      ),
    );
  }, [collections, cardVariant, originalCardVariant]);

  if (collections.length === 0) {
    return null;
  }

  return (
    <div
      className={clsx(
        'my-4',
        originalCardVariant && 'pl-4 text-center sm:pl-8 lg:pr-8',
        wrapperClassName,
      )}
      data-testid="shop-by-collection"
    >
      <Heading.H2>{title}</Heading.H2>
      {subtitle && <p className="mt-2 text-sm">{subtitle}</p>}
      <div
        className={clsx(
          'appsumo-no-scrollbar mt-4 flex w-full snap-x snap-mandatory gap-4 overflow-x-auto',
          originalCardVariant && 'pr-4 lg:gap-8 lg:pr-0',
        )}
      >
        {collectionCards}
      </div>
    </div>
  );
};
