import { ShopByCardConfig } from '~/types/collections';

export const shopByCardConfig: ShopByCardConfig = {
  pinky: {
    bg: 'bg-pinky-dark-20',
    buttonBg: 'bg-pinky',
    gradient: 'from-pinky-dark-20',
  },
  bolt: {
    bg: 'bg-bolt-dark-20',
    buttonBg: 'bg-bolt',
    gradient: 'from-bolt-dark-20',
  },
  dollar: {
    bg: 'bg-dollar-dark-20',
    buttonBg: 'bg-dollar',
    gradient: 'from-dollar-dark-20',
  },
  'ready-red': {
    bg: 'bg-ready-red-dark-20',
    buttonBg: 'bg-ready-red',
    gradient: 'from-ready-red-dark-20',
  },
  default: {
    bg: 'bg-dollar-dark-20',
    buttonBg: 'bg-dollar',
    gradient: 'from-dollar-dark-20',
  },
};
