import { ReactElement } from 'react';
import { InView } from 'react-intersection-observer';

export function LazyLoadCollections({
  loadNextPage,
}: {
  loadNextPage: () => void;
}): ReactElement {
  const onChangeInView = (isElementInView: boolean) => {
    if (isElementInView) {
      loadNextPage();
    }
  };

  return <InView onChange={onChangeInView}></InView>;
}
