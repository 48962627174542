import { ReactNode, useMemo } from 'react';
import Image from 'next/image';
import clsx from 'clsx';

import { AlignType, Button, MoveType } from '@appsumo/dorado-react';
import { Arrow, AutoPlay } from '@egjs/flicking-plugins';
import { ViewportSlot } from '@egjs/react-flicking';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { SmartCollectionComponent } from './types';
import type { DealSkuCardType } from '~/components/sku/types';
import useAttributes from '~/lib/attributes/hook';
import useUser from '~/lib/user';
import { UserObjectProps } from '~/types/user';
import { GA4FormContentValue } from '~/lib/util/constants';

import SwoopingArrow from '~/public/spotlight/swooping-arrow.svg';
import SwoopingArrowSmall from '~/public/spotlight/swooping-arrow-small.svg';
import { SpotlightCard } from '~/components/sku/SpotlightCard';
import { DealAttribute } from '~/components/browse/types';
import { EmailCapture } from '~/components/global/EmailCapture';
import SumoFlicking from '~/components/smartcollections/components/SumoFlicking';

interface Persona {
  name: string;
  value: string;
}

function getPersonas({
  user,
  attrs,
}: {
  user: UserObjectProps | undefined;
  attrs: DealAttribute[];
}): Persona[] {
  const defaultPersona = {
    name: 'Marketing Agencies',
    value: 'marketing-agencies',
  };

  const personas = user?.personas || [];
  const attr = attrs?.find((attr) => attr.slug === 'best_for');
  let topPersonas: Persona[] = [defaultPersona];

  if (attr) {
    topPersonas = (
      (
        personas.map((persona) => {
          const option = attr.type_options?.options?.find(
            (o: any) => o.value === persona.key,
          );

          if (option) {
            return {
              name: option.name,
              value: option.value,
            };
          }
        }) as (Persona | undefined)[]
      ).filter(Boolean) as Persona[]
    ).slice(0, 3);

    if (topPersonas.length < 1) {
      topPersonas = [defaultPersona];
    }
  }

  return topPersonas;
}

const CalloutHeader = ({
  defaultHeader,
  promoLogo,
}: {
  defaultHeader: ReactNode;
  promoLogo: string | null;
}) => {
  if (promoLogo) {
    return (
      <Image
        src={promoLogo}
        alt="promotion logo"
        width="440"
        height="35"
        className="my-2 max-h-[120px] w-auto self-center sm:self-start md:max-h-[150px]"
      />
    );
  }

  return (
    <h2 className="font-header text-[28px] font-bold leading-[32px] sm:text-jumbo">
      {defaultHeader}
    </h2>
  );
};

const CalloutContent = ({
  component,
  personas,
}: {
  component: SmartCollectionComponent;
  personas: Persona[];
}) => {
  if (component.backgroundImage && component.text) {
    return (
      <div
        className="line-clamp-3 sm:text-lg"
        dangerouslySetInnerHTML={{ __html: component.text }}
      />
    );
  }

  return (
    <p className="line-clamp-2 sm:text-lg [&>*:first-child]:before:content-['_'] [&>*:last-child]:before:content-['_and_'] [&>*:last-child]:after:content-['']">
      Explore our top deals for
      {personas.map((persona) => (
        <span
          key={persona.value}
          className="before:content-['_'] after:content-[',']"
        >
          {persona.name}
        </span>
      ))}
    </p>
  );
};

const UnAuthenticatedCallout = ({
  component,
}: {
  component: SmartCollectionComponent;
}) => {
  const unAuthenticatedHeader = () => (
    <span className="line-clamp-2">
      Never pay full price for software again
    </span>
  );

  const inputLabelClasses = clsx(
    'font-header font-semibold',
    component.invertDefaultColors ? 'text-black-pearl' : 'text-white',
  );

  return (
    <div className="flex shrink-0 flex-col gap-y-4 px-4 text-center sm:max-w-lg sm:text-left lg:my-4">
      <CalloutHeader
        defaultHeader={unAuthenticatedHeader()}
        promoLogo={component.promoImage}
      />
      <p className="line-clamp-2 sm:text-lg">
        Sign up to get the hottest software deals sent straight to your inbox
        and get 10% off your first order!
      </p>
      <EmailCapture
        componentName="spotlight-email-capture"
        content={GA4FormContentValue.spotlightHome10off}
        className="flex flex-col gap-y-4 text-left md:max-w-[400px]"
        inputLabelClasses={inputLabelClasses}
      />
    </div>
  );
};

export const Callout = ({
  component,
  personas,
}: {
  component: SmartCollectionComponent;
  personas: Persona[];
}) => {
  const headerText = () => (
    <span className="line-clamp-2">
      Discover <span className="text-dollar">essential tools</span> for your
      business
    </span>
  );

  const buttonText = component.ctaText
    ? component.ctaText
    : 'View recommended deals';
  const buttonLink = component.ctaLink
    ? component.ctaLink
    : '/collections/for-you/';

  return (
    <div className="flex w-full shrink-0 flex-col gap-y-4 px-4 text-center sm:w-auto sm:max-w-lg sm:text-left lg:h-full lg:justify-center lg:py-4">
      <CalloutHeader
        defaultHeader={headerText()}
        promoLogo={component.promoImage}
      />
      <CalloutContent component={component} personas={personas} />
      <div className="flex flex-col gap-y-4 text-left">
        <Button href={buttonLink} className="w-full text-center sm:w-fit">
          {buttonText}
        </Button>
      </div>
      {!component.backgroundImage && (
        <div className="relative mt-auto w-full max-w-[471px] flex-col border-t border-spruce pt-4 sm:border-0 sm:bg-forest sm:px-4 sm:py-2">
          <p className="text-left text-[14px] line-clamp-2">
            <span className="text-[1rem] font-bold">
              Explore our Spotlight deals
            </span>
            <br />
            Recently released deals we couldn&apos;t be more proud of
          </p>
          {/* Flares for spotlight callout */}
          <Image
            src={SwoopingArrow}
            alt="Callout Flare"
            className="absolute bottom-[50px] right-[-75px] hidden align-middle lg:flex"
          />
          <Image
            src={SwoopingArrowSmall}
            alt="Callout Flare"
            className="absolute bottom-0 right-[-60px] hidden align-middle md:flex lg:hidden"
          />
        </div>
      )}
    </div>
  );
};

/**
 * SpotlightCallout
 *
 * Set hard breakpoints for this callout spotlight to match the regular spotlight. This is because we are
 * using a placeholder loading component (based on the callout spotlight) to reduce layout shift
 * while we wait for the user's collection to load. We are currently using the email capture component
 * version of this for anon users. This version uses a lot of space so the regular spotlight has
 * been adjusted to match.
 *
 * Notes:
 * - Text sizing, padding, margins, etc were adjusted to fit in the available space.
 * - The placeholder loading component can be found on the home / index page's SmartCollection component.
 * - "isPromo" was removed to allow a bg color to show while the bg image loads.
 */

export const SpotlightCallout = ({
  deals,
  component,
  priority = false,
  isAuthFromStaticProps,
  handleOverflowContent = false,
}: {
  /**
   * Array of deals from esbrowse
   */
  deals: DealSkuCardType[];
  /**
   * Smart Collection Component props
   */
  component: SmartCollectionComponent;
  /**
   * If true, the first image will be loaded immediately
   */
  priority?: boolean;
  isAuthFromStaticProps: boolean;
  /**
   * If true, it means the next collection will overflow the spotlight,
   * so we need to adjust the height of the spotlight to prevent the overflow
   */
  handleOverflowContent?: boolean;
}) => {
  const plugins = [
    new Arrow({
      prevElSelector: '.flicking-prev',
      nextElSelector: '.flicking-next',
    }),
    new AutoPlay({ duration: 5000, direction: 'NEXT', stopOnHover: true }),
  ];

  const { user, isLoading } = useUser();
  const { data } = useAttributes();
  const personas = useMemo(
    () => getPersonas({ user, attrs: data }),
    [user, data],
  );

  const spotlightStyle = {
    ['--background-image' as string]: `url(${component.backgroundImage})`,
    ['--mobile-background-image' as string]: `url(${
      component.mobileBackgroundImage || component.backgroundImage
    })`,
  } as React.CSSProperties;

  const overflowContentClasses: string = useMemo(() => {
    if (!handleOverflowContent) {
      // default height
      return 'h-[686px] py-4 sm:h-[778px] md:h-[804px] md:py-6 lg:h-[470px]';
    }

    // if any deal has end date (card will increase size by adding countdown element),
    // so use a larger height
    if (deals.some((deal) => deal.active_timer && deal.dates?.end_date)) {
      return 'h-[826px] pt-12 sm:h-[946px] lg:h-[596px]';
    }

    return 'h-[726px] pt-12 sm:h-[846px] lg:h-[546px]';
  }, [handleOverflowContent, deals]);

  /**
   * If making changes to the sizing, be aware of the placeholder component (see comment above)
   * Removed the conditional bg color for "isPromo" to allow bg color to show while bg image loads
   * isAuthFromStaticProps will have the previous auth state while we wait for the user data to load
   */
  return (
    <div
      className={clsx(
        'flex flex-col overflow-hidden bg-[var(--spotlight-background-color)] bg-[image:var(--mobile-background-image)] bg-cover text-white lg:bg-[image:var(--background-image)]',
        overflowContentClasses,
      )}
      style={{
        ...spotlightStyle,
        backgroundColor: component.backgroundColor ?? '#000',
      }}
    >
      <div className="flex h-auto flex-col items-center gap-y-4 sm:items-start sm:gap-x-[70px] lg:ml-[100px] lg:flex-row">
        {user?.is_authenticated || (isLoading && isAuthFromStaticProps) ? (
          <Callout component={component} personas={personas} />
        ) : (
          <UnAuthenticatedCallout component={component} />
        )}
        <div className="w-full pl-4">
          <SumoFlicking
            flickingProps={{
              plugins,
              align: AlignType.PREV,
              circular: true,
              moveType: MoveType.FREE_SCROLL,
            }}
          >
            {deals.map((deal, index) => (
              <SpotlightCard
                key={deal.id}
                deal={deal}
                priority={priority && index === 0}
                hideBanner
              />
            ))}
            <ViewportSlot>
              <div className="mt-4 flex gap-x-2 max-sm:hidden">
                <button className="flicking-prev h-8 w-8 rounded-full bg-dollar text-black-pearl">
                  <span className="sr-only">Previous</span>
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <button className="flicking-next h-8 w-8 rounded-full bg-dollar text-black-pearl">
                  <span className="sr-only">Next</span>
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            </ViewportSlot>
          </SumoFlicking>
        </div>
      </div>
    </div>
  );
};
